
html {
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important;
  background-color: #42b183;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.App {
  margin: 10px auto;
  max-width: 800px;
  background-color: #42b183;
}
.App-header {
  background-color: #58c89a;
  color: white;
  padding: 10px;
  display: flex;
  position: relative;
}
.App-header a {
  text-decoration: none;
}

.App-header h1 {
  color: white;
  flex-grow: 4;
}
.App-header button {
  flex-grow: 4;
}
.App-more {
  padding: 10px;
}
.App-content {
  padding: 10px;
}

.App-entry {
  background-image: url(/layout-images/settings.png);
  background-repeat: no-repeat;
  background-position: calc(100% + 40px) calc(100% - 0%);
  font-family: Verdana;
}

.App-more h2 {
  color: white;
  font-family: Verdana;  
}


.content-page,
.App-more p,
.App-entry p,
.App-content p {
  color: white;
  padding: 10px;
  font-family: Verdana;
}

.App-entry p {
  width: 70%;
}

.Form {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}

.Form label {
  display: block;
  font-weight: bold;
}

.Form .element {
  padding: 10px;
  margin: 5px;
}

.formatting .element-sets button,
.formatting .element input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.formatting .element input {
  padding: 5px 10px;
}

.predef-images {
  overflow-y: scroll;
  height: 120px;
  
}

.predef-images img {
  border: 1px solid transparent;
  float: left;
}
.predef-images img.selected {
  border: 1px solid black;
}

.formatting {
  margin-top: 20px;
  padding: 10px;
}

.formatting .element {
  width: 45%;
  float: left;
}

.formatting .element-full {
  width: 100%;
  float: left;
}

.formatting .element select {
  width: 90%;
}

.clearer { clear: both; }

.Passport {
  margin: 20px auto;
  height: 250px;
  width: 380px;
  display: flex;
  padding: 20px;

  -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  }

.Passport.back {
  text-align: center;
  display: inherit;
  margin-top: -30px;
  -moz-transform: scaleY(-1);
	-o-transform: scaleY(-1);
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
	-ms-filter: "FlipW";
  filter: FlipW;
  transform: scaleY(-1) rotateY(180deg) translateY(-10px);
  margin-bottom: 200px;
}

.Passprt.back {
  position: relative;
}
.Passport.back .notice-origin {
  bottom: 10px;
  position: absolute;
}

.Passport .blocks {
  flex-grow: 4;
}

.Passport .left {
  margin-top: 20px;
  margin-left: 20px;
}

.Passport .right .image {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}

.Passport .right .image img {
  width: 180px;
  background-color: white;
}

.Passport .realname {
  font-size: 70%;
  background-color: white;
}

.Passport .organization {
  margin-top: 20px;
  font-size: 140%;
  background-color: white;
}
.Passport .specials {
  margin-top: 20px;
  background-color: white;
}

.Passport .codename {
  font-size: 150%;
  font-weight: bold;
  background-color: white;
}

.Passport div span {
  font-weight: 50%;
  font-style: italic;
  color: #aaa;
  display: block;
}

.printer button.home {
  padding: 35px 58px!important;
}

.colors-white div{
  color: #fff;
}

button {
  outline: none;
}

.btn-extra-options {
  border-style: none;
  width: 100%;
  text-align: left;
  padding: 5px;
  padding-bottom: 10px;
  font-weight: bold;
  background-image: url(/layout-images/extras-closed@2x.png);
  background-repeat: no-repeat;
  background-position: 95% 5px;
  background-color: white;
}

.btn-extra-options.closed {
  background-color: white;
}

.btn-extra-options.opened {
  /* background-color: #42b183;
  color: white; */
  background-image: url(/layout-images/extras-opened@2x.png);
}

 .container ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.container li {
  display: inline;
}

/*
.container .select-checkbox, 
.container .select-label {
  display: none;
} */

@media only screen and (max-width: 600px) {

  .App { 
    width: 100vw;
    max-width: inherit;
    margin: inherit;
  }

  .App-header {
    display: inherit;
  }
  .App-header h1 {
    font-size: 150%;
  }

  .App-entry p {
    width: 100%;
    padding: 10px;
  }

  #passport-wrapper {
    overflow-x: scroll;
    overflow-y:hidden;
    width: 95vw;
    height: 600px;
  }

  .formatting {
    width: 90%;
  }
  .formatting .element input {
    width: 100%;
  }

  .formatting .element {
    padding: 2px;
    margin-bottom: 5px;
    float: inherit;
    width: inherit;
  }

  .formatting .element-sets button {
    width: 100%;
    margin-bottom: 5px;
    padding: 5px;
  }

  .formatting .element label {
    display:none;
  }
  .extras .formatting .element label {
    display:inherit;
  }

  .App-header .print-button {
    padding: 28px 50px;
  }

  .select-wrap {
    height: 200px;
    overflow-y: scroll;
  }
  .formatting .element-sets .select-wrap button {
    width: 98%;
  }
  .content-sub img {
    width: 100%;
  }
}



@media only screen and (max-width: 350px) {
  .App-header h1 {
    font-size: 120%;
  }

  .printer button.home {
    font-size: 95%;
  }
}

@media only screen and (max-width: 360px) {
  .App-header h1 {
    font-size: 120%;
  }

  .printer button.home {
    font-size: 95%;
  }
}

.layout-1 {
  border: 4px solid black;
  background-color: white;
  background-repeat: repeat;
  }

.layout-2 {
  border: 4px solid black;
  background-color: white;
  background-repeat: repeat;
  }

.layout-3 {
  border: 2px solid black;
  background-color: white;
  background-repeat: repeat;
  }

.layout-4,
.layout-5,
.layout-6,
.layout-7,
.layout-8
{
    border: 2px solid black;
    background-color: white;
}

.printer {
  text-align: center;
}
.printer a {

  color: white;
  font-size: 200%;
  font-weight: bold;
}

.print-button,
.printer button {
  font-size: 100%;
  font-weight: bold;
  border: none;
  color: white;
  padding: 20px 58px;
  background-image: url(/layout-images/btn-bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: transparent;
}

.App-header .print-button {
  margin-right: 45px;
}

.element-sets button {
  margin: 2px 5px;
}

.bm-overlay {
  display: none!important;
}

.content-sub {
  margin-bottom: 20px;
  border-bottom: 1px solid white;
}

.content-sub img {
  max-width: 75%;
}

.content-sub span{
  display: block;
  font-size: 85%;
  font-style: italic;
}


h3.preview {
  text-align: center;
  color: white;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #42b183;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #42b183;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}


/* Wrapper for item list */
.bm-item-list {
  color: #fff;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: #42b183;
}

.bm-menu-wrap {
  outline: none;
}

.bm-menu-wrap div {
  margin-bottom: 10px;
}

.bm-menu-wrap div a {
  color: #fff;
  text-decoration: none;
}

footer {
  display: flex;
}
footer div {
  flex-grow: 4;
}

footer .left {
  background-image: url(/layout-images/characters-boy.png);
  background-repeat: no-repeat;
  background-position: bottom left;
}

footer .center {
  flex-grow:1;
}


footer .right {
  background-image: url(/layout-images/characters-girl.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  height: 170px;
}